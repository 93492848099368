// Themes
export const DEFAULT_THEME = "dark"

// Layout
export const SIDEBAR_WIDTH = 220
export const NAVBAR_HEIGHT = 64
export const MAP_HEIGHT = "500px"
export const MAP_HEIGHT_COMPACT = "250px"
export const FOOTER_HEIGHT = 50
export const SECTIONS_BORDER_RADIUS = "0.25rem"
export const GRID_LAYOUT_GAP = 15
export const DASHBOARD_PADDING = "25px"

// i18n
export const LANGUAGES = Object.freeze({
  en: Object.freeze({
    name: "English",
  }),
  es: Object.freeze({
    name: "Español",
  }),
})

export const LANGUAGE_KEYS = Object.keys(LANGUAGES)
LANGUAGE_KEYS.sort()
export const DEFAULT_LANGUAGE = "en"
export const LANGUAGES_AMOUNT = LANGUAGE_KEYS.length
export const DEFAULT_NAMESPACE = "translations"

// Alerts
export const ALERT_TIME = 60 * 1000
export const ALERT_SETTINGS_TIME = 10 * 60 * 1000
export const POPUP_TIME = 3 * 60 * 1000
export const SUCCESS_POPUP_TIME = 3 * 1000

// Snackbars
export const ALERT_SNACKBAR_TIME = 3 * 60 * 1000

// Sentrisense position refresh
export const SENTRISENSE_POSITION_TIME = 60 * 1000

// Devices refresh time (useDevices hook)
export const DEVICES_TABLE_TIME = 10 * 60 * 1000

// Group devices refresh time (useDeviceGroups hook)
export const DEVICE_GROUPS_TIME = 20 * 60 * 1000

// Orgs refresh time (useOrgs hook)
export const ORGS_TIME = 30 * 60 * 1000

// Date range picker refresh
export const DATE_RANGE_TIME = 5 * 60 * 1000

// Loader timeout
export const LOADER_TIME = 2 * 1000

// Branding
const lessUrl = window.location.href.includes("lessindustries")
export const IMAGOTYPE = lessUrl
  ? "/images/less/logo-less-light.svg"
  : "/images/sentrisense/logo-sentrisense-light.svg"
export const ISOTYPE = lessUrl
  ? "/images/less/isotype-less-light.svg"
  : "/images/sentrisense/isotype-sentrisense-light.svg"

// URL
export const DEFAULT_TARGET = "/"

// USER
export const PASSWORD_MIN_LENGTH = 8
export const TOKEN_TIME = 5 * 60 * 1000

// PERIOD OF TIME IN MILLISECONDS
export const ONE_DAY_MILLISECONDS = 60 * 60 * 24 * 1000
export const TEN_MINUTES = 60 * 10 * 1000

// REGEX
export const MIN_8_CHAR = /^.{8,}$/
export const AT_LEAST_ONE_UPPERCASE = /[A-Z]/
export const AT_LEAST_ONE_LOWERCASE = /[a-z]/
export const AT_LEAST_ONE_LOWERCASE_AND_ONE_UPPERCASE = /(?=.*[a-z])(?=.*[A-Z])/
export const AT_LEAST_ONE_NUMBER = /[0-9]/
export const AT_LEAST_ONE_SPECIAL_CHAR = /[^a-zA-Z0-9]/

// Password
export const PASSWORD = Object.freeze({
  // The strength score is between 0 and 4, being '0' the weakest score and '4' the
  // highest possible score.

  // If password strength score is less to 3, the password won't be accepted as valid
  MIN_VALID_STRENGTH: 3,
})

// user roles
export const USER_ROLES = Object.freeze({
  viewer: "viewer",
  editor: "editor",
  admin: "admin",
  owner: "owner",
})

// device types
export const DEVICE_TYPES: readonly string[] = Object.freeze([
  "sentrisense",
  "weather_station",
  "virtual_powerline",
  "virtual_span",
])
export const NEW_DEVICE_BACKEND = "influx"

// oauth login providers
export const OAUTH_PROVIDERS = Object.freeze({
  GOOGLE: "google",
  MICROSOFT: "microsoft",
})
